import React from "react"

import Layout from "../components/layout"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

class NotFoundPage extends React.Component {

	componentDidMount() {
		document.getElementsByTagName('html')[0].classList.add('seo-page');
	}

	componentWillUnmount() {
		document.getElementsByTagName('html')[0].className = "";
	}

	render() {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<Header />

				<main className="main not-found-page">
					<h1>Page not Found :(</h1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>

				</main>

				<Footer />
			</Layout>
		)
	}
}

export default NotFoundPage
